export const GOOGLE_MAPS_KEY = process.env.VUE_APP_GOOGLE_MAPS_KEY
export const GOOGLE_ANALYTICS_KEY = process.env.VUE_APP_GOOGLE_ANALYTICS_KEY
export const ACCESS_KDI = process.env.VUE_APP_ACCESS_KDI
export const URL_IMG = process.env.VUE_APP_URL_IMG_COMISSIONAMENTO
export const REALMS_LIST = process.env.VUE_APP_REALMS_LIST
export const URL_BASE_KEYCLOAK = process.env.VUE_APP_URL_BASE_KEYCLOAK
export const CLIENT_ID_KEYCLOAK = process.env.VUE_APP_CLIENT_ID
export const ROOT_API_KDI = process.env.VUE_APP_ROOT_API_KDI
export const ROOT_API_TURING = process.env.VUE_APP_ROOT_API_TURING
export const BASE_URL_PATH = process.env.VUE_APP_BASE_URL
export const BASE_AUTH_PREFIX = process.env.VUE_APP_AUTH_PREFIX


