import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import VCalendar from 'v-calendar';
import PrimeVue from 'primevue/config';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import VueGtag from "vue-gtag";
import mitt from 'mitt'
import { GOOGLE_MAPS_KEY, GOOGLE_ANALYTICS_KEY } from "./services/config";
import initializeKeycloak from './keycloak';

const emitter = mitt()
const keycloak = initializeKeycloak();
keycloak.init({ onLoad: 'check-sso' }).then(() => {
    const app = createApp(App)
    app.directive('tooltip', Tooltip)
    app.use(ToastService)
    app.use(PrimeVue)
    app.use(VueLoading)
    app.use(VCalendar, {})
    app.use(router)
    app.directive('tooltip', Tooltip)
    app.use({
        install(app) {
            app.config.globalProperties.emitter = emitter;
        }
    })
    app.provide('keycloak', keycloak);
    app.use(VueGoogleMaps, {
        load: {
            key: GOOGLE_MAPS_KEY,
        },
    })
    app.use(VueGtag, {
        appName: 'turing',
        pageTrackerScreenviewEnabled: true,
        config: { id: GOOGLE_ANALYTICS_KEY },
    }, router)
    app.mount('#app')
})